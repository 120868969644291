import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as Lo from "lodash";
import { toastSuccess } from "utils/toasts";
import { useHandleError } from "services";
import { isNumeric } from "utils/helpers";

import { useRootStore } from "store";
import {
  FieldCheckBox,
  FieldDatepicker,
  FieldNumericInput,
  FormCard,
  FormCol,
  YupBoolean,
  YupDateReq,
  YupNumeric,
  YupNumericReq,
} from "components/common";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  gqlAddRsCollector,
  gqlGetRsCollectorById,
  gqlUpdateRsCollector,
  RsCollectorFormInput,
} from "../graphql";
import { Col, Row } from "react-bootstrap";
import FieldSelectRecyclingStation from "pages/reports/rs-deliveries/fs-recycling-stations";
import promiseModalDialog from "components/common/dialogs/modal-dialog-promise";
import FieldSelectCollector from "components/fs-collectors";

export const RsCollectorFormPage = () => {
  const { handleGraphqlError } = useHandleError("RsCollectorFormPage");
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    RsId: YupNumericReq,
    CollectorId: YupNumericReq,
    IsActive: YupBoolean,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const newRecord = useMemo<FormValuesType>(
    () =>
      ({
        IsActive: true,
      } as FormValuesType),
    []
  );

  const [addRecord] = useMutation(gqlAddRsCollector);
  const [updateRecord] = useMutation(gqlUpdateRsCollector);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(
    gqlGetRsCollectorById,
    {
      fetchPolicy: "network-only",
    }
  );
  const data = isNew
    ? newRecord
    : rawData
    ? rawData[Object.keys(rawData)[0]]
    : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new RsCollectorFormInput()));
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log("[RsCollector] New:", mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `Επιτυχής Δημιουργία Εγγραφής [${
              d.data[Object.keys(d.data)[0]]?.id
            }]`
          );
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError("mutation-add", error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log("[RsCollector] Update:", mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `Επιτυχής Αποθήκευση Εγγραφής [${
              d.data[Object.keys(d.data)[0]].id
            }]`
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError("mutation-update", error);
        });
    }
  };

  /* **** Render **** */
  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={
          isNew
            ? `Καινούργια Σύνδεση`
            : `Σύνδεση: ${data?.RecyclingStation?.name} - ${data?.Collector?.Name}`
        }
        onSubmit={handleSubmit}
        onReturn={null}
        //onDelete={handleDelete}
        permitDelete={true}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
        isNew={isNew}
      >
        <FormFields />
      </FormCard>
    );
  }
};

const FormFields = () => {
  //const { values, setFieldValue }: any = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <FieldSelectRecyclingStation
            as={FormCol}
            name="RsId"
            label="Σταθμός Ανακύκλωσης"
            required={true}
            //defaultValues={!!data ? data[0] : undefined}
          />
          <FieldSelectCollector
            as={FormCol}
            name="CollectorId"
            required
            //defaultValues={!!data ? data[0] : undefined}
          />
          <FieldCheckBox
            as={FormCol}
            label={"Ενεργό"}
            name="IsActive"
            //placeholder={('- specify a value - ')}
            required={true}
          />
        </Col>
      </Row>
    </>
  );
};
