import gql from "graphql-tag";

export const gqlGetCalendarData = gql`
  query getRsCalendars(
    $where: String
    $whereCap: String
    $whereCapExp: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRsCalendars(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        CollectorId
        PlannedDate
        PlannedWeight
        Canceled
        CanceledBy
        RecyclingStation {
          ID
          name
        }
        Collector {
          ID
          Name
        }
      }
      recordCount
    }
    getRsCapacities(
      where: $whereCap
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        RsColor
        Day1
        Day2
        Day3
        Day4
        Day5
        Day6
        Day7
        RecyclingStation {
          ID
          name
        }
      }
      recordCount
    }
    getRsCapacityExceptions(
      where: $whereCapExp
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        ExceptionDate
        ExceptionDateTo
        IsActive
        CapacityWeight
        RecyclingStation {
          ID
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlAddRsCalendar = gql`
  mutation addRsCalendar($data: RsCalendarInput) {
    addRsCalendar(data: $data) {
      id
    }
  }
`;

export const gqlUpdateRsCalendar = gql`
  mutation updateRsCalendar($data: RsCalendarInput) {
    updateRsCalendar(data: $data) {
      id
    }
  }
`;

export const gqlGetRsCapacities = gql`
  query getRsCapacities(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRsCapacities(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        RsColor
        Day1
        Day2
        Day3
        Day4
        Day5
        Day6
        Day7
        FirstDate
        LastDate
        FirstUser
        LastUser
        RecyclingStation {
          ID
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlGetRsCapacityExceptions = gql`
  query getRsCapacityExceptions(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRsCapacityExceptions(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        ExceptionDate
        ExceptionDateTo
        IsActive
        CapacityWeight
        FirstDate
        LastDate
        FirstUser
        LastUser
        RecyclingStation {
          ID
          name
        }
      }
      recordCount
    }
  }
`;

export const gqlGetRsCapacityById = gql`
  query getRsCapacityById($id: Int) {
    getRsCapacityById(id: $id) {
      id
      RsId
      RsColor
      Day1
      Day2
      Day3
      Day4
      Day5
      Day6
      Day7
      RecyclingStation {
        ID
        name
      }
    }
  }
`;

export class RsCapacityFormInput {
  id: number;
  RsId: number;
  RsColor: string;
  Day1: number;
  Day2: number;
  Day3: number;
  Day4: number;
  Day5: number;
  Day6: number;
  Day7: number;
}

export const gqlUpdateRsCapacity = gql`
  mutation updateRsCapacity($data: RsCapacityInput) {
    updateRsCapacity(data: $data) {
      id
    }
  }
`;

export const gqlGetRsCapacityExceptionById = gql`
  query getRsCapacityExceptionById($id: Int) {
    getRsCapacityExceptionById(id: $id) {
      id
      RsId
      ExceptionDate
      ExceptionDateTo
      IsActive
      CapacityWeight
      RecyclingStation {
        ID
        name
      }
    }
  }
`;

export class RsExceptionFormInput {
  id: number;
  RsId: number;
  CapacityWeight: number;
  ExceptionDate: Date;
  ExceptionDateTo: Date;
  IsActive: boolean;
}

export const gqlUpdateRsCapacityException = gql`
  mutation updateRsCapacityException($data: RsCapacityExceptionInput) {
    updateRsCapacityException(data: $data) {
      id
    }
  }
`;

export const gqlAddRsCapacityException = gql`
  mutation addRsCapacityException($data: RsCapacityExceptionInput) {
    addRsCapacityException(data: $data) {
      id
    }
  }
`;

export const gqlDeleteRsCapacityException = gql`
  mutation deleteRsCapacityException($id: Int) {
    deleteRsCapacityException(id: $id)
  }
`;

export const gqlGetRsCollectors = gql`
  query getRsCollectors(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRsCollectors(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id
        RsId
        CollectorId
        IsActive
        RecyclingStation {
          ID
          name
        }
        Collector {
          ID
          Name
        }
      }
      recordCount
    }
  }
`;

export const gqlGetRsCollectorById = gql`
  query getRsCollectorById($id: Int) {
    getRsCollectorById(id: $id) {
      id
      RsId
      CollectorId
      IsActive
      RecyclingStation {
        ID
        name
      }
      Collector {
        ID
        Name
      }
    }
  }
`;

export class RsCollectorFormInput {
  id: number;
  RsId: number;
  CollectorId: number;
}

export const gqlUpdateRsCollector = gql`
  mutation updateRsCollector($data: RsCollectorInput) {
    updateRsCollector(data: $data) {
      id
    }
  }
`;

export const gqlAddRsCollector = gql`
  mutation addRsCollector($data: RsCollectorInput) {
    addRsCollector(data: $data) {
      id
    }
  }
`;
